<template>
    <div v-if="product" :id="product.item.id" class="d-flex flex-column" style="min-height: 22rem">
        <div>
            <b-link :to="'/app/product/details/'+product.item.id" class="product-grid-image">
                <div class="card_badge bg-primary" v-if="countInCart > 0">
                    {{ countInCart }}
                </div>
                <b-img
                    v-if="product.item.images && product.item.images.length >0"
                    :alt="`${product.item.name}-${product.item.id}`"
                    fluid
                    class="card-img-top"
                    :src="ApiHippoUrl+`/cdn/${product.item.images[0].path}`"
                />
                <b-img
                    v-else
                    :alt="`${product.item.name}-${product.item.id}`"
                    fluid
                    class="card-img-top"
                    src="@/assets/images/no-photo.svg"
                />
            </b-link>
        </div>
        <div class="item-name" style="margin-left: 5px; margin-right: 5px; margin-top: 5px">
            <b-link class="text-body" @click="goToProductDetails(product.item.id)">
                <h6 style="font-size: 12px">{{ product.item.name | kit_cut }}</h6>
            </b-link>
        </div>

        <div class="mt-auto">
            <div class="item-price" style="margin: 0 5px; font-size: 12px">
                <h5 class="text-warning d-inline-block" style="margin-right: 5px">
                    {{ product.price.common_price.amount | decimal }}</h5>
                <h5 class="d-inline-block">{{ product.price.common_price.is_national ? 'сум' : '' }}</h5>
            </div>
            <div style="margin: 5px 5px" v-if="product.item.package_measurements.length > 0">
                <small class="font-weight-bold d-block" style="margin-right: 2px;font-size: 10px">Выберите кол-во в упаковке:</small>
                <b-badge class="cursor-pointer" :variant="selectedPackageMeasurement === pack ? 'warning': ''"
                         style="margin-right: 2px; font-size: 8px" small
                         v-for="(pack, index) in product.item.package_measurements" @click="selectMeasurement(pack)" :key="index">
                    {{ pack.quantity }}
                </b-badge>
            </div>
            <div>
                <b-button-group size="sm" class="w-100" v-if="productExistInCart">
                    <b-button style="padding: 5px" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm"
                              @click="decrementProduct">
                        <span
                            class="text-white">- {{
                                selectedPackageMeasurement && selectedPackageMeasurement.quantity !==1 ? selectedPackageMeasurement.quantity : ''
                            }}</span>
                    </b-button>
                    <b-button style="padding: 5px" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                              size="sm"
                              @click="incrementProduct">
                        <span
                            class="text-white">+ {{
                                selectedPackageMeasurement && selectedPackageMeasurement.quantity !==1 ? selectedPackageMeasurement.quantity : ''
                            }}</span>
                    </b-button>
                </b-button-group>
                <b-button-group size="sm" class="w-100" v-else>
                    <b-button style="padding: 5px" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm"
                              variant="primary"
                              @click="onAddItemToCart"
                    ><span class="text-white">{{ translate.translate("key", "dobavit") }}</span>
                    </b-button>
                </b-button-group>
            </div>
        </div>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import translate from "@/translation/translate";

import {ApiHippoUrl} from '@/constants/settings'

export default {
    name: 'ProductGrid',
    props: ['product', 'order'],
    directives: {
        Ripple,
    },
    data() {
        return {
            ApiHippoUrl,
            translate,
            selectedPackageMeasurement: {name: '', quantity: 1}
        }
    },
    computed: {
        countInCart() {
            let order_item = this.order && this.order.items.find(order_item => {
                return order_item.global_item_id === this.product.item.id
            })

            if (order_item) {
                return order_item.quantity
            }
            return 0
        },
        productExistInCart() {
            let exist = this.order && this.order.items.find(order_item => {
                return order_item.global_item_id === this.product.item.id
            });
            return !!exist
        }
    },
    methods: {
        selectMeasurement(selectedMeasurement) {
            if (this.selectedPackageMeasurement === selectedMeasurement)
                this.selectedPackageMeasurement = {name: '', quantity: 1};
            else
                this.selectedPackageMeasurement = selectedMeasurement;
        },
        goToProductDetails(item_id) {
            this.$emit('clickedProductDetails');
            this.$router.push({name: 'product-details', params: {id: item_id}})
        },
        incrementProduct() {
            this.$emit('incrementQuantity', this.product, this.selectedPackageMeasurement)
        },
        decrementProduct() {
            this.$emit('decrementQuantity', this.product, this.selectedPackageMeasurement)
        },
        onAddItemToCart() {
            this.$emit('addProduct', this.product, this.selectedPackageMeasurement)
        }
    },
}
</script>

<style lang="scss">
.item-name {
    font-size: 12px;
}

.product-grid-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    flex-shrink: 0;

    img {
        width: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.product-card-body {
    padding: 5px 1rem 1rem !important;
}
</style>
