<template>
    <div>
        <div class="d-flex align-items-center mb-1">
            <h6 @click="$router.go(-1)" class="d-flex cursor-pointer mr-1">
                <feather-icon
                    size="20"
                    class="mr-1"
                    icon="ArrowLeftIcon"
                />
            </h6>
            <h5 class="category-name-title text-center">
                {{ category ? category.name : '' }}
            </h5>
        </div>
        <b-form-input
            class="search-form mr-2 mb-2"
            ref="InputSearchName"
            v-model="searchName"
            :placeholder="translate.translate('key', 'poiskPoNazvaniyuIliArtikulu')"
            @input="searchDebounce()"
            autocomplete="off"
        />

        <div class="products" v-if="loadedProducts.length > 0">
            <ProductGrid :product="product"
                         :order="order"
                         style="min-width: 0;"
                         class="card"
                         v-for="(product, index) in loadedProducts" :key="index"
                         @addProduct="onAddProduct"
                         @incrementQuantity="onIncrementQuantity"
                         @decrementQuantity="onDecrementQuantity"
            />
        </div>
        <h4 class="text-center" v-else>{{ translate.translate('key', 'productNeNayden') }}</h4>

        <div class="text-center mb-4" v-if="loadedProducts.length > 0 && this.categoryProducts.length > this.page">
            <b-button variant="primary" @click="loadMore">{{ translate.translate('key', 'pokazatYeshyo') }} 18
            </b-button>
        </div>
    </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler'
import BCardCode from '@core/components/b-card-code/BCardCode'
import ProductGrid from '@/components/ProductGrid'
import {_} from 'vue-underscore'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Ripple from 'vue-ripple-directive'
import translate from '@/translation/translate'

export default {
    name: 'Category',
    directives: {
        Ripple,
    },
    components: {
        ProductGrid,
        BCardCode,
        DarkToggler,

    },
    data() {
        return {
            translate,
            searchName: this.$route.query.product || '',
            category: null,
        }
    },
    computed: {
        ...mapGetters({
            page: 'getPage',
            order: 'getOrder',
            items: 'getProducts',
            categoriesTree: 'getCategoriesTree',
            categoryProducts: 'getCategoryProducts'
        }),
        loadedProducts() {
            return this.categoryProducts.slice(0, this.page)
        }
    },
    methods: {
        ...mapMutations(['setPage', 'setCategoryProducts']),
        ...mapActions(['incrementQuantity', 'decrementQuantity', 'addProduct']),
        searchDebounce: _.debounce(function () {
            this.update()
            this.setCategoryProducts(this.searchStringInArray(this.searchName))
        }, 1000),
        update() {
            this.updateRouteQuery({
                page: this.page,
                product: this.searchName,
                category_id: this.category ? this.category.id : null
            })
        },
        updateRouteQuery({
                             product,
                             page,
                             category_id
                         }) {
            let routeQuery = {}
            if (product) {
                routeQuery.product = product
            }
            if (page) {
                routeQuery.page = page
            }
            if (category_id) {
                routeQuery.category_id = category_id
            }
            this.$router.replace({
                name: this.$route.name,
                query: routeQuery
            })
                .catch(err => {
                })
        },

        onIncrementQuantity(product, selectedPackageMeasurement) {
            this.incrementQuantity({product, selectedPackageMeasurement})
        },
        onDecrementQuantity(product, selectedPackageMeasurement) {
            this.decrementQuantity({product, selectedPackageMeasurement})
        },
        onAddProduct(product, selectedPackageMeasurement) {
            this.addProduct({product, quantity: selectedPackageMeasurement.quantity})
        },

        filterItemsByCategoryId(categoryId) {
            let productsList = []
            let categoriesTree = this.findCategoryFromTree(this.categoriesTree, categoryId)

            let parentItems = this.items.filter(i => {
                if (i.item.category && categoriesTree) {
                    return i.item.category && i.item.category.id === categoriesTree.id
                }
            })
            if (categoriesTree) {
                if (parentItems.length > 0) {
                    productsList.push(...parentItems)
                }
                this.findItemsCategoryTree(categoriesTree.childs, productsList)
            }
            return productsList
        },
        findItemsCategoryTree(categoriesTree, productsList) {
            categoriesTree.forEach(childCategory => {
                let list = this.items.filter(i => {
                    return i.item.category && i.item.category.id === childCategory.id
                })
                if (list.length > 0) {
                    productsList.push(...list)
                }
                if (childCategory.childs.length > 0) {
                    this.findItemsCategoryTree(childCategory.childs, productsList)
                }
            })
        },
        findCategoryFromTree(categoriesTree, categoryId) {
            for (let i = 0; i < categoriesTree.length; i++) {
                let cat = categoriesTree[i]
                if (cat.id === categoryId) {
                    return cat
                }
                cat = this.findCategoryFromTree(cat.childs, categoryId)
                if (cat !== null) {
                    return cat
                }
            }
            return null
        },

        searchStringInArray(str) {
            return this.items.filter(item => {
                let name = item.item.name.toLowerCase()
                let sku = item.item.sku ? item.item.sku.toLowerCase() : ''
                return name.match(str.toLowerCase()
                    .trim()) || sku.match(str.toLowerCase()
                    .trim())
            })
        },

        loadMore() {
            if (this.page > this.categoryProducts.length) {
                return
            }
            this.setPage(this.page + 18)
        },
        scrollToProduct(product_id) {
            let el = document.getElementById(product_id)
            this.$scrollTo(el, 500, {offset: -120})
        },
    },
    watch: {
        '$route.query.category_id': {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (this.$route.query.category_id) {
                        this.setCategoryProducts(this.filterItemsByCategoryId(newVal))
                        this.category = this.findCategoryFromTree(this.categoriesTree, newVal)
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'product-details') {
                setTimeout(() => {
                    vm.scrollToProduct(from.params.id)
                }, 1000)
            }
        })
    },
}
</script>

<style scoped>
</style>
